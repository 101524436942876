/* Container to center the table and pagination */
.table-container {
  margin: 0 auto;
  padding: 20px;
  max-width: 1200px;
  width:100%;
}

.table-container {
  overflow-x: auto; /* Enable horizontal scrolling */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

.custom-table {
  min-width: 600px; /* Set a minimum width to allow proper scrolling */
}


/* Table styling */
.custom-table {
  border-collapse: separate;
  border-spacing: 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-table th,
.custom-table td {
  padding: 12px 15px;
  vertical-align: middle;
}

.custom-table th {
  background-color: #f8f9fa;
  color: #333;
  font-weight: 600;
  border-bottom: 2px solid #dee2e6;
}

.custom-table td {
  border-bottom: 1px solid #dee2e6;
}

.custom-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Hover effect for rows */
.custom-table tbody tr:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

/* Pagination container */
.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

/* Pagination buttons */
.pagination-container .btn {
  margin: 0 5px;
  padding: 6px 12px;
  border-radius: 4px;
}

/* Pagination information */
.pagination-info {
  margin: 0 10px;
  font-size: 1rem;
  font-weight: 500;
}

/* Dropdown for changing page size */
.pagination-select {
  margin-left: 10px;
  padding: 5px;
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
}

/* Improve button styling */
.pagination-container .btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.pagination-container .btn-primary:disabled {
  background-color: #ccc;
  border-color: #ccc;
  cursor: not-allowed;
}

.pagination-container .btn-primary:hover {
  background-color: #0056b3;
}
