/********** Template CSS **********/
:root {
    --primary: #1E1E1E;
    --secondary: #FEA116;
    --light: #F1F8FF;
    --dark: #0F172B;
}

.fw-medium {
    font-weight: 500 !important;
}

.fw-semi-bold {
    font-weight: 600 !important;
}

.back-to-top {
    position: fixed;
    display: none;
    right: 45px;
    bottom: 45px;
    z-index: 99;
}


/*** Spinner ***/
#spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-out, visibility 0s linear .5s;
    z-index: 99999;
}

#spinner.show {
    transition: opacity .5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
}


/*** Button ***/
.btn {
    font-weight: 500;
    text-transform: uppercase;
    transition: .5s;
}

.btn.btn-primary,
.btn.btn-secondary {
    color: #FFFFFF;
}

.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    border-radius: 5px;
}

.custom-border-radius {
    border-radius: 12px;
    /* Adjust the value as needed */
}



/*** Navbar ***/
.navbar-dark .navbar-nav .nav-link {
    margin-right: 30px;
    padding: 25px 0;
    color: #FFFFFF;
    font-size: 15px;
    text-transform: uppercase;
    outline: none;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
    color: var(--primary);
}

@media (max-width: 991.98px) {
    .navbar-dark .navbar-nav .nav-link {
        margin-right: 0;
        padding: 10px 0;
    }
}


/*** Header ***/
.carousel-caption {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(15, 23, 43, 0.1);
    z-index: 1;
}

.carousel-control-prev,
.carousel-control-next {
    width: 10%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    width: 0.5rem !important;
    height: 0.5rem !important;
}

@media (max-width: 768px) {
    #header-carousel .carousel-item {
        position: relative;
        min-height: 450px;
    }

    #header-carousel .carousel-item img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.page-header {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: fill;
    min-height: 700px;
}

.page-header-inner {
    background: linear-gradient(-75deg, #004380BA 50%, transparent 50%);
    min-height: 700px;
}

@media (max-width: 1000px) {
    .page-header-inner {
        /* background: #002c54ba; */
        background: linear-gradient(-75deg, #00396aba 60%, transparent 80%);
        min-height: 400px;
    }
}


.breadcrumb-item+.breadcrumb-item::before {
    color: var(--light);
}

.booking {
    position: relative;
    margin-top: -100px !important;
    z-index: 1;
}

/*** Section Title ***/
.section-title {
    font-size: 12px;
    position: relative;
    display: inline-block;
}

/* .section-title::before {
    position: absolute;
    content: "";
    width: 45px;
    height: 2px;
    top: 50%;
    left: -55px;
    margin-top: -1px;
    background: var(--primary);
} */

.section-title::after {
    position: absolute;
    content: "";
    width: 45px;
    height: 2px;
    top: 50%;
    right: -55px;
    margin-top: -1px;
    background: var(--primary);
}

.section-title.text-start::before,
.section-title.text-end::after {
    display: none;
}


/*** Service ***/
.service-item {
    height: 320px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background: #FFFFFF;
    box-shadow: 0 0 45px rgba(0, 0, 0, .08);
    transition: .5s;
}

.service-item:hover {
    background: var(--primary);
}

.service-item .service-icon {
    margin: 0 auto 30px auto;
    width: 65px;
    height: 65px;
    transition: .5s;
}

.service-item i,
.service-item h5,
.service-item p {
    transition: .5s;
}

.service-item:hover i,
.service-item:hover h5,
.service-item:hover p {
    color: #FFFFFF !important;
}


/*** Youtube Video ***/
.video {
    position: relative;
    height: 100%;
    min-height: 500px;
    background: linear-gradient(rgba(15, 23, 43, .1), rgba(15, 23, 43, .1)), url(../../public/assets/img/ourBlogsBg.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.video .btn-play {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    box-sizing: content-box;
    display: block;
    width: 32px;
    height: 44px;
    border-radius: 50%;
    border: none;
    outline: none;
    padding: 18px 20px 18px 28px;
}

.custom-button {
    display: inline-flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    /* Semi-transparent background */
    padding: 12px 24px;
    border-radius: 40px;
    /* Rounded edges */
    color: #0d3b66;
    /* Dark blue text */
    font-weight: 600;
    /* Semi-bold text */
    font-size: 1rem;
    /* Adjust font size as needed */
    text-decoration: none;
    transition: background-color 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* Optional shadow */
}

.custom-button:hover {
    background-color: rgba(255, 255, 255, 1);
    /* Slightly darker on hover */
}

.play-icon {
    font-size: 1.2rem;
    /* Adjust icon size */
    margin-right: 8px;
    /* Space between icon and text */
}


.video .btn-play:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 100px;
    height: 100px;
    background: var(--primary);
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
}

.video .btn-play:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 100px;
    height: 100px;
    background: var(--primary);
    border-radius: 50%;
    transition: all 200ms;
}

.video .btn-play img {
    position: relative;
    z-index: 3;
    max-width: 100%;
    width: auto;
    height: auto;
}

.video .btn-play span {
    display: block;
    position: relative;
    z-index: 3;
    width: 0;
    height: 0;
    border-left: 32px solid var(--dark);
    border-top: 22px solid transparent;
    border-bottom: 22px solid transparent;
}

@keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }

    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
    }
}

#videoModal {
    z-index: 99999;
}

#videoModal .modal-dialog {
    position: relative;
    max-width: 800px;
    margin: 60px auto 0 auto;
}

#videoModal .modal-body {
    position: relative;
    padding: 0px;
}

#videoModal .close {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 0px;
    top: -30px;
    z-index: 999;
    font-size: 30px;
    font-weight: normal;
    color: #FFFFFF;
    background: #000000;
    opacity: 1;
}


/*** Testimonial ***/
.testimonial {
    background: url(../../public/assets/img/homebg2.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.testimonial-carousel {
    padding-left: 65px;
    padding-right: 65px;
}

.testimonial-carousel .testimonial-item {
    padding: 30px;
    margin: 5px;
    /* background: linear-gradient(rgba(15, 23, 43, .7), rgba(15, 23, 43, .7)) */
}

.testimonial-carousel .owl-nav {
    position: absolute;
    width: 100%;
    height: 40px;
    top: calc(50% - 20px);
    left: 0;
    display: flex;
    justify-content: space-between;
    z-index: 1;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
    position: relative;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    background: var(--primary);
    border-radius: 2px;
    font-size: 18px;
    transition: .5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
    color: var(--primary);
    background: #FFFFFF;
}


/*** Team ***/
.team-item,
.team-item .bg-primary,
.team-item .bg-primary i {
    transition: .5s;
}

.team-item:hover {
    border-color: var(--secondary) !important;
}

.team-item:hover .bg-primary {
    background: var(--secondary) !important;
}

.team-item:hover .bg-primary i {
    color: var(--secondary) !important;
}


/*** Footer ***/
.newsletter {
    position: relative;
    z-index: 1;
}

.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; /* Smooth scrolling for iOS */
  }
  

.footer {
    position: relative;
    margin-top: -110px;
    padding-top: 180px;
}

.footer .btn.btn-social {
    margin-right: 5px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--light);
    border: 1px solid #FFFFFF;
    border-radius: 35px;
    transition: .3s;
}

.footer .btn.btn-social:hover {
    color: var(--primary);
}

.footer .btn.btn-link {
    display: block;
    margin-bottom: 5px;
    padding: 0;
    text-align: left;
    color: #FFFFFF;
    font-size: 15px;
    font-weight: normal;
    text-transform: capitalize;
    transition: .3s;
}

.footer .btn.btn-link::before {
    position: relative;
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    margin-right: 10px;
}

.footer .btn.btn-link:hover {
    letter-spacing: 1px;
    box-shadow: none;
}

.footer .copyright {
    padding: 25px 0;
    font-size: 15px;
    border-top: 1px solid rgba(256, 256, 256, .1);
}

.footer .copyright a {
    color: var(--light);
}

.footer .footer-menu a {
    margin-right: 15px;
    padding-right: 15px;
    border-right: 1px solid rgba(255, 255, 255, .3);
}

.footer .footer-menu a:last-child {
    margin-right: 0;
    padding-right: 0;
    border-right: none;
}

/* General Exam Card Styles */
.exams {
    padding: 5px;
    position: relative;
    margin-top: -200px !important;
    z-index: 1;
}

.exam-card {

    margin: 15px auto;
    border-radius: 20px;
    background: linear-gradient(160.14deg, #64A2D5 2.35%, #004380 86.73%);
}

/* Responsive Exam List View */
.exam-list-view {
    border-radius: 10px;
    color: white;
    font-family: Arial, sans-serif;
}

/* Countdown and Exam Item Styles */
.exam-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.exam-item:last-child {
    border-bottom: none;
}

.exam-details {
    display: flex;
    align-items: center;
}

.exam-icon {
    color: white;
    margin-right: 10px;
    font-size: 1.5rem;
}

.exam-info {
    text-align: left;
}

.exam-title {
    margin: 0;
    font-weight: bold;
    font-size: 1.1rem;
}

.exam-date {
    margin: 0;
    font-size: 0.9rem;
}

/* Countdown Styles */
.exam-countdown {
    display: flex;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.6);
    border: 2px solid #0077cc;
    border-radius: 8px;
    padding: 5px;
    font-size: 1.0rem;
    text-align: center;
    color: white;
}

.countdown-unit {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 5px 8px;
    border-radius: 8px;
    min-width: 40px;
    margin: 2px;
}

.countdown-number {
    font-size: 1.0rem;
    font-weight: bold;
}

.countdown-label {
    font-size: 0.5rem;
    margin-top: 5px;
    color: #cccccc;
}

/* Media Queries for Smaller Screens */
@media (max-width: 768px) {

    .exams {
        padding: 5px;
        margin-top: -50px !important;
    }

    .exam-title {
        font-size: 1rem;
    }

    .exam-date {
        font-size: 0.8rem;
    }

    .countdown-number {
        font-size: 0.9rem;
    }

    .countdown-label {
        font-size: 0.45rem;
    }
}

@media (max-width: 576px) {
    .exams {
        padding: 5px;
        margin-top: -50px !important;
    }

    .exam-card {
        margin: 10px;
        padding: 10px;
    }

    .exam-item {
        display: flex;
        flex-direction: column;
    }

    .exam-title {
        font-size: 0.9rem;
    }

    .exam-date {
        font-size: 0.75rem;
    }

    .countdown-number {
        font-size: 0.8rem;
    }

    .countdown-label {
        font-size: 0.4rem;
    }
}

/* sliders */
/* Responsive styling for testimonial cards */
.testimonial-item {
    padding: 1.5rem;
  }
  
  @media (max-width: 768px) {
    .testimonial-item {
      padding: 1rem;
    }
  
    .testimonial-item p {
      font-size: 0.9rem;
    }
  
    .testimonial-item h6 {
      font-size: 1rem;
    }
  
    .testimonial-item small {
      font-size: 0.8rem;
    }
  }

 /* course details */
 .course-types {
    margin-top: 15px;
    padding-left: 20px;
  }
  
  .course-types li {
    margin-bottom: 10px;
  }
  
  .course-types ul {
    margin-top: 5px;
    padding-left: 20px;
    list-style-type: circle;
  }
  
  .course-types ul li {
    margin-bottom: 5px;
  }

  .container {
    font-family: Arial, sans-serif;
    margin: 20px auto;
    max-width: 1200px;
  }
  
  .course-header {
    background-color: #eaf4ff;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .course-title {
    font-size: 24px;
    font-weight: bold;
    color: #0056b3;
  }
  
  .course-description {
    margin-top: 10px;
    color: #555;
    font-size: 16px;
  }
  
  .course-section {
    margin-top: 30px;
  }
  
  .section-heading {
    font-size: 20px;
    font-weight: bold;
    color: #ff6f00;
    margin-bottom: 10px;
  }
  
  .section-content {
    font-size: 16px;
    color: #333;
  }
  
  .detail-item {
    margin: 10px 0;
  }
  
  .timetable {
    width: 100%;
    border: 1px solid #ccc;
    margin-top: 20px;
    border-collapse: collapse;
  }
  
  .timetable th {
    text-align: center;
    border: 1px solid #ccc;
  }

  .timetable td {
    padding: 20px;
    text-align: left;
    border: 1px solid #ccc;
  }
  
  .course-types {
    margin-top: 15px;
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .course-types li {
    margin: 5px 0;
  }
  .subtopics-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: left;
  }
  
  .subtopic-row {
    display: flex;
    align-items: left;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
  }
  
  .subtopic-title {
    flex-grow: 1; /* Ensures the title takes up available space */
    font-weight: bold;
  }
  
  .subtopic-row button {
    flex-shrink: 0; /* Prevent buttons from resizing */
  }
  /* loading */
  .book-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full viewport height for a centered spinner */
  }
  
  .pages {
    position: relative;
    width: 50px;
    height: 60px;
    perspective: 1000px;
  }
  
  .page {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #64A2D5;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    animation: flip 4s cubic-bezier(0.25, 1, 0.5, 1) infinite;
  }
  
  .page:nth-child(2) {
    animation-delay: 0.2s;
    background: #004380;
  }
  
  .page:nth-child(3) {
    animation-delay: 0.4s;
    background: #004380;
  }
  
  @keyframes flip {
    0% {
      transform: rotateY(0);
      opacity: 1;
    }
    50% {
      transform: rotateY(180deg);
      opacity: 0.5;
    }
    100% {
      transform: rotateY(360deg);
      opacity: 1;
    }
  }
  
  .loading-text {
    margin-top: 20px;
    font-size: 1.2rem;
    font-weight: bold;
    color: #004380;
    animation: fade 4s infinite;
  }
  
  @keyframes fade {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
  
  
  