/* General Card Styling */
.dashboard-card {
    color: white;
    border-radius: 15px;
    padding: 20px;
    text-align: left;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  /* Gradient Backgrounds */
  .card-green {
    background: linear-gradient(135deg, #28a745, #71c78b);
  }
  
  .card-blue {
    background: linear-gradient(135deg, #007bff, #6db9f9);
  }
  
  .card-teal {
    background: linear-gradient(135deg, #17a2b8, #6dd8e6);
  }
  
  .card-purple {
    background: linear-gradient(135deg, #6f42c1, #b79ad7);
  }
  
  /* Icon Styling */
  .card-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    background: white;
    color: black;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Title and Count Styling */
  .dashboard-card h5 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
  }
  
  .dashboard-card h2 {
    margin: 10px 0 0;
    font-size: 28px;
    font-weight: 700;
  }
  