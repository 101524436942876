.blog-mgt-container {
    background-color: #f8f9fa;
    min-height: 100vh;
    padding-top: 20px;
  }
  
  .header-section {
    padding-bottom: 15px;
    border-bottom: 1px solid #e9ecef;
  }
  
  .page-title {
    font-weight: 600;
    font-size: 1.8rem;
  }
  
  .add-blog-button {
    font-weight: 500;
    width: 200px;
    margin-left: 20px;
  }
  
  .blog-card {
    transition: transform 0.3s ease-in-out;
  }
  
  .blog-card:hover {
    transform: scale(1.02);
  }
  
  .blog-title {
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  .blog-description {
    color: #6c757d;
  }
  
  .blog-footer {
    background-color: #f1f3f5;
    border-top: 1px solid #dee2e6;
  }
  
  .no-blogs-text {
    text-align: center;
    color: #6c757d;
  }
  
  .blog-modal .modal-title {
    font-weight: 600;
  }
  
  .sidebar-column {
    border-right: 1px solid #e9ecef;
  }
  
  .content-column {
    background-color: white;
  }
  
  .card-image-container {
    height: 200px;
    overflow: hidden;
    border-bottom: 1px solid #e9ecef;
  }
  
  .card-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  